import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "inter",
    "12600": {
      fontSize: 12,
      fontWeight: 600,
      fontFamily: "inter",
    },
    "18700": {
      fontSize: 18,
      fontWeight: 700,
      fontFamily: "inter",
    },
  },
});

export default theme;
