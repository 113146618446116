import React from "react";
import { Box, Typography } from "@mui/material";

import { GeneralItem, Dot } from "../components";

const letterList = [
  { name: "نامه ۱", id: 1000 },
  { name: "نامه ۱", id: 1001 },
];

export const Letters = () => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "black",
          height: "45px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h5" color="red">
          نامه ها
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          padding: "20px 20px",
          gap: "1px",
        }}
      >
        {letterList.map((letter) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Dot />
            <GeneralItem
              key={letter.name}
              name={letter.name}
              id={letter.id.toString()}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Letters;
