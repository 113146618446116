import React, { FunctionComponent } from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

interface BookItemProps {
  id: string;
  name: string;
}

export const BookItem: FunctionComponent<BookItemProps> = ({ name, id }) => {
  return (
    <Button
      to={id + "/index"}
      state={{ title: name }}
      sx={{ color: "black" }}
      component={Link}
    >
      <Box>
        <Typography>{name}</Typography>
      </Box>
    </Button>
  );
};

export default BookItem;
