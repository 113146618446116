import { createBrowserRouter } from "react-router-dom";
import {
  Books,
  Home,
  PdfView,
  BookIndex,
  Notes,
  Letters,
  Poems,
} from "../screens";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/books",
    element: <Books />,
  },
  {
    path: "/notes",
    element: <Notes />,
  },
  {
    path: "/letters",
    element: <Letters />,
  },
  {
    path: "/poems",
    element: <Poems />,
  },
  {
    path: "/books/:id",
    element: <PdfView />,
  },
  {
    path: "/notes/:id",
    element: <PdfView />,
  },
  {
    path: "/poems/:id",
    element: <PdfView />,
  },
  {
    path: "/letters/:id",
    element: <PdfView />,
  },
  {
    path: "/books/:id/index",
    element: <BookIndex />,
  },
]);

export { RouterProvider } from "react-router-dom";
