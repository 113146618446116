import React, { FunctionComponent } from "react";
import { Box } from "@mui/material";

interface SpaceProps {
  size: number;
  dir: "vertical" | "horizontal";
}

export const Space: FunctionComponent<SpaceProps> = ({ size, dir }) => {
  return (
    <Box
      sx={{
        width: dir === "horizontal" ? size : 0,
        height: dir === "vertical" ? size : 0,
      }}
    ></Box>
  );
};
