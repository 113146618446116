import React, { useRef, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Document, Page } from "react-pdf";
import queryString from "query-string";

import { useLocation, useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

// const bookLink = {
//   url: "https://bootorab.s3.ir-thr-at1.arvanstorage.ir/komail.pdf",
// };

const { innerWidth: width, innerHeight: height } = window;

export const PdfViewer = (props: any) => {
  const location = useLocation();
  const { id } = useParams();
  // const bookContent = require(`../assets/${id}.pdf`);

  const numPages = useRef(0);
  // const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(
    Number(queryString.parse(location.search)?.["page"]) || 1
  );
  const [loading, setLoading] = useState(true);
  const [pageWidth, setPageWidth] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);

  function onDocumentLoadSuccess({
    numPages: nextNumPages,
  }: {
    numPages: number;
  }) {
    // setNumPages(nextNumPages);
    numPages.current = nextNumPages;
  }

  function onPageLoadSuccess() {
    setPageHeight(window.innerHeight);
    setPageWidth(window.innerWidth);
    setLoading(false);
  }

  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
    standardFontDataUrl: "standard_fonts/",
  };

  function goToNextPage() {
    if (pageNumber < numPages.current) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  }

  function goToPreviousPage() {
    if (pageNumber > numPages.current) {
      setPageNumber((prevPageNumber) => prevPageNumber - 1);
    }
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "black",
          height: "45px",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        <Typography variant="h5" color="red">
          {location.state.title}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Document
          file={{
            url: `https://bootorab.s3.eu-north-1.amazonaws.com/${id}.pdf`,
          }}
          // file={bookContent}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
          renderMode="canvas"
          className=""
        >
          <Page
            className=""
            key={pageNumber}
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            onLoadSuccess={onPageLoadSuccess}
            onRenderError={() => setLoading(false)}
            height={
              pageWidth > 600 ? Math.max(pageHeight * 0.9, 10) : undefined
            }
            width={pageWidth < 600 ? Math.max(pageWidth * 0.8, 390) : undefined}
          />
        </Document>
      </Box>
      <Box
        sx={{
          display: "flex",
          position: "absolute",
          bottom: "10px",
          backgroundColor: "black",
          height: "45px",
          width: width - 25,
          // width: `calc(${width} - 10px)`,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button onClick={goToPreviousPage}>
          <ChevronLeftIcon color="red" height={40} />
        </Button>
        <Typography color="red">{`${pageNumber}/${numPages.current}`}</Typography>
        <Button onClick={goToNextPage}>
          <ChevronRightIcon color="red" height={40} />
        </Button>
      </Box>
    </Box>
  );
};

export default PdfViewer;
