import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";

export const BookIndex = () => {
  const { id } = useParams();
  const { state } = useLocation();

  const bookIndex = require(`../assets/${id}.json`);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "black",
          height: "45px",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        <Typography variant="h5" color="red">
          فهرست
        </Typography>
      </Box>
      {Object.entries(bookIndex).map(([index, page]) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            to={"../books/" + id + "?page=" + page}
            state={{ title: state.title }}
            sx={{ color: "black" }}
            component={Link}
          >
            <Box
              sx={{
                textAlign: "right",
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "end",
                gap: "10px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "black",
                  height: "10px",
                  width: "10px",
                  borderRadius: "45px",
                  position: "absolute",
                  top: "10px",
                }}
              />
              <Typography sx={{ paddingRight: "20px" }}>{index}</Typography>
            </Box>
          </Button>
        </Box>
      ))}
    </Box>
  );
};
