import React from "react";
import { Box, Typography } from "@mui/material";

import { BookItem, Dot } from "../components";

const bookList = [
  { name: "شرح دعای کمیل", id: 1 },
  // { name: "پیامبر را بشناسیم", id: 2 },
];

export const Books = () => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "black",
          height: "45px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h5" color="red">
          کتب
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          padding: "20px 20px",
          gap: "1px",
        }}
      >
        {bookList.map((book) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
							alignItems: "center",
							gap: "10px"
            }}
          >
            <Dot />
            <BookItem
              key={book.name}
              name={book.name}
              id={book.id.toString()}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Books;
