import React, { FunctionComponent, PropsWithChildren } from "react";
import {
  MenuBook,
  StickyNote2,
  LibraryBooks,
  LocalLibrary,
} from "@mui/icons-material";
import { Box, IconButton, Typography, Link } from "@mui/material";

type IconType = "books" | "letters" | "notes" | "poems";
interface AppIconProps {
  name: string;
  type: IconType;
}

export const AppIcon: FunctionComponent<AppIconProps> = ({ name, type }) => {
  return (
    <RenderLink type={type}>
      <Box
        style={{
          border: "solid 1px black",
          borderRadius: "7px",
          padding: "0 10px 10px",
        }}
      >
        <IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <RenderIcon type={type} />
            <Typography variant="18700" color="red">
              {name}
            </Typography>
          </Box>
        </IconButton>
      </Box>
    </RenderLink>
  );
};

export default AppIcon;

function RenderIcon({ type }: { type: IconType }) {
  const style = { height: "100px", width: "100px", color: "black" };
  switch (type) {
    case "books":
      return <MenuBook sx={style} />;
    case "letters":
      return <LibraryBooks sx={style} />;
    case "notes":
      return <StickyNote2 sx={style} />;
    case "poems":
      return <LocalLibrary sx={style} />;
    default:
      return <MenuBook sx={style} />;
  }
}

export const RenderLink: FunctionComponent<
  PropsWithChildren<{ type: IconType }>
> = ({ type, children }) => {
  let href = undefined;
  switch (type) {
    case "books":
      href = "/books";
      break;
    case "notes":
      href = "/notes";
      break;
    case "poems":
      href = "/poems";
      break;
    case "letters":
      href = "/letters";
      break;
    default:
      break;
  }
  return <Link href={href}>{children}</Link>;
};
